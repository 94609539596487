import {fetchController} from '../controllers/fetch';

(() => {

    if (document.querySelector('.content__password-create')) {

        document.getElementById('passwordResetButton').addEventListener('click', resetPassword);

    }

})();

function resetPassword() {

    let new_password = document.getElementById('password').value;

    let confirm_new_password = document.getElementById('password-confirm').value;

    let reset_token = document.getElementById('reset-token').value;

    if (new_password !== confirm_new_password) {

        alert('Password\'s do not match');
        return;

    }

    let errMsg = 'Password Reset unsuccessful. Please Try Again.';

    fetchController('/user/password-create', 'POST', {new_password, reset_token}, errMsg, (response) => {

        if (response.msg === 'Error') {

            alert(errMsg);
            return;

        }

        alert('Password Reset Successful. Please sign in with new password');

        window.location.href = '/user/signin';

    });   

}