import {fetchController} from '../controllers/fetch';

(() => {

    if (document.querySelector('.content__password-reset')) {

        document.getElementById('passwordResetButton').addEventListener('click', attemptPasswordReset);

    }

})();

function attemptPasswordReset() {

    let username = document.getElementById('username').value.trim();

    if (!username) {

        return;

    }

    let errMsg = 'Password Reset Email Sent. If you do not see it in your inbox, please check your spam.';

    fetchController('/user/password-reset', 'POST', {username}, errMsg, (response) => {

        console.log(response);

    });   

}