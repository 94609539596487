(() => {

    if (document.querySelector('.nav')) {

        document.querySelector('.hamburger').addEventListener('click', (event) => {

            document.querySelector('.nav').classList.toggle('open');

        });

        document.querySelector('.nav__close').addEventListener('click', (event) => {

            document.querySelector('.nav').classList.toggle('open');

        });

        document.querySelectorAll('.nested-nav').forEach(el => {

            el.addEventListener('click', toggleNestedNav);

        });

    }

})();

function toggleNestedNav(event) {

    let nested_nav = event.target.closest('.nested-nav');

    if (nested_nav) {

        nested_nav.classList.toggle('show-nested-nav');

    }

}