export function fetchController(url, method, data = null, errMsg, callback) {

    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    let requestOptions = {
        method: method,
        headers: {
            'CSRF-Token': csrfToken,
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    };

    if (data && method !== 'GET') {

        requestOptions.body = JSON.stringify(data);

    };

    let request = new Request(url, requestOptions);

    fetch(request)
        .then(resp => resp.json())
        .then(response => callback(response))
        .catch(err => {

            // eslint-disable-next-line no-undef
            if (typeof Bugsnag !== 'undefined') {

                // eslint-disable-next-line no-undef
                Bugsnag.notify(err);

            };

            alert(`${errMsg} Please contact john.sommerfeld@antelopeayso.org.\n\n${err}`);
            console.log(err);
        });

}

export function fetchPDF(url, data, filename, errMsg) {

    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    let requestOptions = {
        method: 'POST',
        headers: {
            'CSRF-Token': csrfToken,
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    };

    requestOptions.body = JSON.stringify(data);

    let request = new Request(url, requestOptions);

    fetch(request)
        .then(response => {
            return response.blob();
        })
        .then(blob => {
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = `${filename}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(err => {
            // eslint-disable-next-line no-undef
            if (typeof Bugsnag !== 'undefined') {

                // eslint-disable-next-line no-undef
                Bugsnag.notify(err);

            };

            alert(`${errMsg} Please contact john.sommerfeld@antelopeayso.org.\n\n${err}`);
            console.log(err);
        });

}
