import {fetchController} from '../controllers/fetch';

(() => {

    if (document.querySelector('.content__signin')) {

        document.getElementById('signinButton').addEventListener('click', attemptSignin);

    }

})();

function attemptSignin() {

    let username = document.getElementById('username').value;

    let password = document.getElementById('password').value;

    let errMsg = 'The Email and Password do not match.';

    let data = {
        username,
        password
    };

    fetchController('/user/signin', 'POST', data, errMsg, (response) => {

        if (response.authenticated) {

            const searchParams = new URLSearchParams(window.location.search);
            let additionalParams = [];

            searchParams.forEach((value, key) => {

                if (key !== 'refer') {

                    additionalParams.push(`${key}=${value}`);
        
                }
        
            });

            let redirect = `${searchParams.get('refer')}${additionalParams.length > 0 ? `&${additionalParams.join('&')}` : ''}`;

            if (!redirect) {

                redirect = '/';

            }

            window.location.href = redirect;

        }

    });

    // let requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'CSRF-Token': csrfToken,
    //         'Content-Type': 'application/json'

    //     },
    //     credentials: 'same-origin',
    //     body: JSON.stringify(data)
    // };

    // let request = new Request('/user/signin', requestOptions);

    // fetch(request)
    //     .then(resp => resp.json())
    //     .then(response => {

    //         if (response.authenticated) {

                
    //             const searchParams = new URLSearchParams(window.location.search);
    //             let additionalParams = [];

    //             searchParams.forEach((value, key) => {

    //                 if (key !== 'refer') {

    //                     additionalParams.push(`${key}=${value}`);
        
    //                 }
        
    //             });

    //             const redirect = `${searchParams.get('refer')}${additionalParams.length > 0 ? `&${additionalParams.join('&')}` : ''}`;

    //             window.location.href = redirect;

    //         }

    //     })
    //     .catch(err => {

    //         // eslint-disable-next-line no-undef
    //         if (typeof Bugsnag !== 'undefined') {

    //             // eslint-disable-next-line no-undef
    //             Bugsnag.notify(err);

    //         };

    //         alert(`${errMsg}\n\n${err}`);
    //         console.log(err);
    //     });
    

}