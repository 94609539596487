import {fetchController} from '../controllers/fetch';

(() => {

    if (document.querySelector('.content__signup')) {

        document.getElementById('signupButton').addEventListener('click', attemptSignup);

    }

})();

function attemptSignup() {

    let first_name = document.getElementById('first_name').value;

    let last_name = document.getElementById('last_name').value;

    let password = document.getElementById('password').value;

    let confirm_password = document.getElementById('password-confirm').value;

    let invite_token = document.getElementById('invite-token').value;

    if (!first_name) {

        alert('First Name is required');
        return;

    }

    if (!last_name) {

        alert('Last Name is required');
        return;

    }

    if (password !== confirm_password) {

        alert('Passwords do not match');
        return;

    }

    let errMsg = 'There was an error signing up.';

    let data = {
        first_name,
        last_name,
        password,
        invite_token
    };

    fetchController('/user/signup', 'POST', data, errMsg, (response) => {

        if (response.msg === 'Error') {

            alert(`${errMsg}\n\n${response.error}`);
            return;

        }

        window.location.href = '/user/signin';

    });

}